$(document).ready(function () {
    var searchContainerId = 'search';
    var withAutoComplete = false;
    var searchInput = document.getElementById(searchContainerId);

    if (searchInput && withAutoComplete) {
        console.log('search input');
        // attach this event when user is typing data in search
        searchInput.addEventListener("input", reducboxAutoComplete);
        // is using up/down or enter will capture it
        searchInput.addEventListener("keydown", reducboxBrowseSuggests);
        // close all suggsetions when someone clicks in the document:
        document.addEventListener("click", function (e) {
            closeAllLists(e.target);
        });
    }

    /**
     * summernote is a wysiwyg editor to format textarea input.
     * before to set a summernote class (class="summernote") you need to push the 
     * js+css required 
     * 
     * @push('scripts')
     * <link href="{{ asset('/css/summernote.css') }}" rel="stylesheet">
     * <script src="{{ asset('/js/summernote.js') }}"></script>
     * @endpush
     */
    if ($('.summernote').length) {
        $('.summernote').summernote({
            placeholder: 'description',
            tabsize: 2,
            height: 100,
            toolbar: [
                ['style', ['style']],
                ['font', ['bold', 'underline', 'clear']],
                ['fontname', ['fontname']],
                ['color', ['color']],
                ['para', ['ul', 'ol', 'paragraph']],
                ['insert', ['link']],
                //['table', ['table']],
                //['insert', ['link', 'picture', 'video']],
                //['view', ['fullscreen', 'codeview', 'help']]
            ]
        }
        );
    }

    /**
     * This function will add some suggestion in the search bar.
     *
     * @param {*} event
     */
    function reducboxAutoComplete(event) {
        var searchTyped = this.value;
        console.log('saisie : ' + searchTyped);
        if (searchTyped.length < 3) {
            return;
        }

        console.log("search query is being sent for " + searchTyped);
        currentFocus = -1;
        console.log("searchContainerId is : " + searchContainerId + " - search done : " + searchTyped + "/ currentFocus : " + currentFocus);
        event.preventDefault();
        $.ajaxSetup({
            headers: {
                "X-CSRF-TOKEN": $('meta[name="_token"]').attr("content")
            }
        });
        $.ajax({
            url: "/searchAjax",
            method: "get",
            data: {
                searchQuery: searchTyped
            },
            success: function (result) {
                if (result.error != 0) {
                    console.log("La recherche a produit une erreur.");
                    return false;
                }

                nbResults = Object.keys(result.data).length;
                console.log(
                    "there are " + nbResults + " results for query : " + searchTyped
                );
                // clear any previously loaded options in the datalist

                previousResultListObj = document.getElementById(
                    searchContainerId + "autocomplete-list"
                );
                if (previousResultListObj) {
                    previousResultListObj.remove();
                }

                if (nbResults <= 0) {
                    return;
                }

                var a = document.createElement("div");
                a.setAttribute("id", searchContainerId + "autocomplete-list");
                a.setAttribute("class", "autocomplete-items");
                // append the DIV element as a child of the parentNode container:
                document.getElementById(searchContainerId).parentNode.appendChild(a);

                for (i = 0; i < nbResults; i++) {
                    item = result.data[i];
                    console.log(
                        "Item title for index : " + i + " is :" + item["title"]
                    );
                    // create a DIV element for each matching element:
                    b = document.createElement("div");
                    b.setAttribute("class", "autocomplete-item");
                    b.innerHTML = item["title"];
                    // insert a input field that will hold the current array item's value:
                    b.innerHTML += '<input type="hidden" value="' + item["title"].replace(/([\"])/g, '\\"') + '">';
                    // execute a function when someone clicks on the item value (DIV element):
                    b.addEventListener("click", function (e) {
                        // insert the value for the autocomplete text field:
                        document.getElementById(searchContainerId).value = this.getElementsByTagName("input")[0].value;

                        // close the list of autocompleted values, or any other open lists of autocompleted values:
                        closeAllLists();
                    });
                    a.appendChild(b);
                }
            }
        });
    }

    function reducboxBrowseSuggests(event) {
        var x = document.getElementById(searchContainerId + "autocomplete-list");
        if (x) {
            x = x.getElementsByTagName("div");
        }

        if (event.keyCode == 40) {
            /*If the arrow DOWN key is pressed,
                    increase the currentFocus variable:*/
            currentFocus++;
            /*and and make the current item more visible:*/
            addActive(x);
        } else if (event.keyCode == 38) {
            //up
            console.log("up key has been pressed");
            /*If the arrow UP key is pressed,
                    decrease the currentFocus variable:*/
            currentFocus--;
            /*and and make the current item more visible:*/
            addActive(x);
        } else if (event.keyCode == 13) {
            event.preventDefault();
            if (currentFocus > -1) {
                /*and simulate a click on the "active" item:*/
                if (x) {
                    x[currentFocus].click();
                }
            }
            document.getElementById("searchForm").submit();
        } else if (event.keyCode == 9) {
            console.log("tab key has been pressed");
            if (currentFocus > -1) {
                /*and simulate a click on the "active" item:*/
                if (x) {
                    x[currentFocus].click();
                }
            }
        }
    }

    function addActive(x) {
        // a function to classify an item as "active":
        if (!x) return false;
        // start by removing the "active" class on all items:
        removeActive(x);
        if (currentFocus >= x.length) currentFocus = 0;
        if (currentFocus < 0) currentFocus = x.length - 1;
        // add class "autocomplete-active":
        x[currentFocus].classList.add("autocomplete-active");
    }

    function removeActive(x) {
        // a function to remove the "active" class from all autocomplete items:
        for (var i = 0; i < x.length; i++) {
            x[i].classList.remove("autocomplete-active");
        }
    }

    function closeAllLists(elmnt) {
        // close all autocomplete lists in the document, except the one passed as an argument:
        var x = document.getElementsByClassName("autocomplete-items");
        if (x) {
            for (var i = 0; i < x.length; i++) {
                if (elmnt != x[i] && elmnt != searchInput) {
                    x[i].parentNode.removeChild(x[i]);
                }
            }
        }
    }
    console.log("autocomplete.js loaded");
});